import { Injector } from '@angular/core';
import { BackendService } from '@services/backend.service';
import { GenericObject } from '@utils/models/Types';
import { HttpConfigProps } from '../../shared/enums/backend.enum';
import { RouteParams } from '../enums/route.enum';

export class SectionManagerService<SectionType> {
  protected _backendService: BackendService;
  protected endpointSectionMap: GenericObject;

  constructor(injector: Injector, endpointSectionMap: GenericObject<SectionType>) {
    this._backendService = injector.get(BackendService);
    this.endpointSectionMap = endpointSectionMap;
  }

  saveSection(sectionData: SectionType, sectionBody = {}, entityId?: any) {
    const endpointItem = this.endpointSectionMap[sectionData as string];

    if (entityId) {
      return this._backendService.put(endpointItem, sectionBody, {
        [HttpConfigProps.URL_PARAMS]: { [RouteParams.ID]: entityId }
      });
    } else {
      const body = { [sectionData as string]: sectionBody };
      return this._backendService.post(endpointItem, body);
    }
  }
}
